@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');

header {
    background-color: #F2ECE9;
    padding-top: 25px;
    position: fixed;
    width: 100%;
    height: 45px;
    top: 0px;
    left: 0px;
    z-index: 5;
}

.logo {
    float: left;
    padding-left: 20px;
    font-family: Julius Sans One;
    font-size: 26px;
}

.links {
    font-family: Julius Sans One;
    font-size: 20px;
    float: right;
    padding: 0px 20px;
}

a {
    color: black;
    transition: color 0.3s ease;
    text-decoration: none;
    
    &:hover {
        color: darken(#fff, 20%);
        transform: scale(1.5);
    }
}

li {
    list-style-type: none;
}

@media screen and (max-width: 5120px) {
    header {
        height: 155px;
    }
    
    .logo {
        padding-left: 3vw;
        font-size: 75px;
    }

    .links {
        font-size: 60px;
        padding: 0.5vh 3vw;
    }
}

@media screen and (max-width: 4096px) {
    header {
        height: 115px;
    }
    
    .logo {
        padding-left: 3vw;
        font-size: 75px;
    }

    .links {
        font-size: 60px;
        padding: 0.5vh 3vw;
    }
}

@media screen and (max-width: 3000px) {
    header {
        height: 100px;
    }
    
    .logo {
        padding-left: 3vw;
        font-size: 65px;
    }

    .links {
        font-size: 50px;
        padding: 0.5vh 3vw;
    }
}

@media screen and (max-width: 1921px) {
    header {
        height: 70px;
    }
    
    .logo {
        padding-left: 3vw;
        font-size: 45px;
    }

    .links {
        font-size: 35px;
    }
}

@media screen and (max-width: 1540px) {
    header {
        height: 50px;
    }
    
    .logo {
        padding-left: 30px;
        font-size: 35px;
    }

    .links {
        font-size: 26px;
    }
}

@media screen and (max-width: 768px) {
    header {
        background-color: #F2ECE9;
        padding-top: 25px;
        position: fixed;
        width: 100%;
        height: 45px;
        top: 0px;
        left: 0px;
        z-index: 5;
    }
    
    .logo {
        float: left;
        padding-left: 20px;
        font-family: Julius Sans One;
        font-size: 26px;
    }
    
    .links {
        font-family: Julius Sans One;
        font-size: 20px;
        float: right;
        padding: 0px 20px;
    }
    
    a {
        color: black;
        transition: color 0.3s ease;
        text-decoration: none;
        
        &:hover {
            color: darken(#fff, 20%);
            transform: scale(1.5);
        }
    }
    
    li {
        list-style-type: none;
    }
}

@media screen and (max-width: 414px) {
    header {
        height: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .logo {
        font-size: 18px;
    }

    .links {
        font-size: 14px;
        padding: 3px 0 0 0;
    }
}