// ================== Universal Styling ==================
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Lora&family=Raleway:wght@200&display=swap');

.App {
    text-align: center;
    }

body {
    position: relative;
    background-color: white;
}

h1 {
    font-family: Julius Sans One;
    font-size: 36px;
    font-weight: 300;
    text-align: left;
    padding-top: 10px;
    padding-left: 50px;
}

h2 {
    font-family: Raleway;
    font-size: 24px;
}

h3 {
    font-family: Playfair;
    font-size: 24px;
    margin-bottom: 5px;
    text-align: left;
    color: #696055;
}   

h5 {
    font-size: 18px;
    font-family: Raleway;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    color: #696055;
}

p {
    text-align: left;
    margin-top: 10px;
    font-family: Raleway;
    font-size: 18px;
    color: #8F8374;
}

// ================================================ Home.js ================================================
.homePage {
    background-image: url("./pages/images/lightbulb.jpg");
    background-repeat: no-repeat;
    width: 90vw;
    height: 85vh;
    opacity: 0.75;
    margin-top: 120px;
    margin-bottom: 100px;
    margin-left: 5vw;

    h1 {
        padding-left: 0;
    }

    p {
        color: black;
    }
}

.container {
    background-color: #F2ECE9;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    width: 45vw;
    padding: 1vh 2vw 2vh 3vw;
    opacity: 0.65;
}

p {
    font-weight: bold;
}

@media screen and (max-width: 5120px) {         //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        background-image: url("./pages/images/lightbulb1920.jpg");
        background-repeat: no-repeat;
        width: 90vw;
        height: 85vh;
        opacity: 0.75;
        margin-top: 360px;
        margin-bottom: 100px;
        margin-left: 12vw;
    
        h1 {
            padding-left: 0;
            font-size: 55px;
        }

        h2 {
            font-size: 45px;
        }
    
        p {
            color: black;
            font-size: 30px;
        }
    }
    
    .container {
        background-color: #F2ECE9;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        width: 16vw;
        padding: 1vh 2vw 2vh 3vw;
        opacity: 0.65;
    }
    
    p {
        font-weight: bold;
    }
}

@media screen and (max-width: 4096px) {         //++++++++++++++++++++++++++++++++++++++++++
    .container {
        width: 20vw;
    }
}

@media screen and (max-width: 3000px) {         //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        margin-top: 240px;
        margin-left: 5vw;
    }
    
    .container {
        width: 34vw;
    }
}

@media screen and (max-width: 1921px) {         //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        background-image: url("./pages/images/lightbulb.jpg");
        background-repeat: no-repeat;
        width: 90vw;
        height: 85vh;
        opacity: 0.75;
        margin-top: 150px;
        margin-bottom: 100px;
        margin-left: 5vw;
    
        h1 {
            padding-left: 0;
            font-size: 40px;
        }

        h2 {
            font-size: 35px;
        }
    
        p {
            color: black;
            font-size: 20px;
        }
    }
    
    .container {
        background-color: #F2ECE9;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        width: 34vw;
        padding: 1vh 2vw 2vh 3vw;
        opacity: 0.65;
    }
    
    p {
        font-weight: bold;
    }
}

@media screen and (max-width: 1540px) {         //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        background-image: url("./pages/images/lightbulb.jpg");
        background-repeat: no-repeat;
        width: 90vw;
        height: 85vh;
        opacity: 0.75;
        margin-top: 120px;
        margin-bottom: 100px;
        margin-left: 5vw;
    
        h1 {
            padding-left: 0;
            font-size: 36px;
            font-weight: 300;
            text-align: left;
        }

        h2 {
            font-size: 24px;
        }
    
        p {
            color: black;
            text-align: left;
            margin-top: 10px;
            font-size: 18px;
        }
    }
    
    .container {
        background-color: #F2ECE9;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        width: 45vw;
        padding: 1vh 2vw 2vh 3vw;
        opacity: 0.65;
    }
    
    p {
        font-weight: bold;
    }
}

@media screen and (max-width: 1024px) {         //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        width: 100vw;
        height: 85vh;
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {          //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        background-image: url("./pages/images/lightbulb685.jpg");
        margin-top: 115px;
        margin-bottom: 50px;
        width: 90vw;
        margin-left: 5vw;

        h1 {
            padding-top: 0;
            font-size: 30px;
        }
    
        h2 {
            font-size: 20px;
        }
    
        p {
            font-size: 15px;
            padding-right: 10px;
        }
    }

    .container {
        background-color: white;
        width: 45vw;
    }
}

@media screen and (max-width: 540px) {          //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        background-image: url("./pages/images/lightbulb365.jpg");
        margin-top: 100px;
        height: 120vh;
        width: 64.5vw;
        margin-left: 19vw;

        h1 {
            font-size: 30px;
        }
    
        h2 {
            font-size: 20px;
            padding-top: 282px;
        }
    
        p {
            font-size: 18px;
        }
    }

    .container {
        width: 60vw;
    }
}

@media screen and (max-width: 414px) {          //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        margin-top: 70px;
        width: 90vw;
        margin-left: 6vw;
    }

    .container {
        width: 85vw;
    }

    p {
        padding-right: 17px;
    }
}

@media screen and (max-width: 375px) {          //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        background-image: url("./pages/images/lightbulb260.jpg");
        width: 69vw;
        height: 115vh;
        margin-left: 16vw;

        h1 {
            font-size: 25px;
        }
    
        h2 {
            padding-top: 175px;
        }
    
        p {
            font-size: 16px;
            padding-right: 10px;
        }
    }

    .container {
        width: 67vw;
    }
}

@media screen and (max-width: 360px) {          //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        width: 72vw;
        height: 120vh;
        margin-left: 15vw;
    }

    .container {
        width: 70vw;
    }
}

@media screen and (max-width: 320px) {          //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        width: 90vw;
        height: 130vh;
        margin-left: 10vw;
    }
    
    .container {
        width: 79vw;
    }
}

@media screen and (max-width: 280px) {          //++++++++++++++++++++++++++++++++++++++++++
    .homePage {
        width: 90vw;
        height: 115vh;
        margin-left: 5vw;
    }

    .container {
        width: 90vw;
    }
}

// ================================================ About.js ================================================

.aboutPage {
    margin-top: 90px;
    margin-bottom: 100px;
    width: 100vw;
    height: 140vh;
    
    h1 {
        margin-bottom: 0;
    }
}

.aboutContainer {
    background-image: url("./pages/images/screen.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;    
    opacity: 0.75;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
}

.aboutContent {
    padding-top: 31vh;
    padding-right: 33vw;
    padding-left: 37.5vw;
    
    h3, h4, p, #synonyms {
        color: black;
    }
}

h4 {
    font-family: Playfair;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    border-bottom: 1px solid black;
}   

#synonyms {
    font-style: oblique;
    margin-top: 25px;
    margin-bottom: 0;
}

.skillsContainer {
    h1 {
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        margin-left: 35vw;
        margin-right: 35vw;
        border-bottom: 1px solid black;
        padding: 0 0 4px;
    }
}

.icon, .psqlIcon {
    width: 50px;
    height: 50px;
}

.sequelizeIcon {
    width: 96px;
    height: 40.078px;
}

.iconLabel {
    font-size: 20px;
    font-family: Raleway;
    font-weight: bold;
    color: #696055;
}

ul {
    display: flex;
    margin: 0;
    padding: 0;
    
    li {
        list-style-type: none;
        padding: 0px 10px;
    }
}

.techIcons {
    margin-top: 35px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 50px;
}

@media screen and (max-width: 5120px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        margin-top: 125px;
        height: 150vh;
        
        h1 {
            padding-top: 4vh;
            padding-left: 5vw;
            font-size: 65px;
        }
    }

    .aboutContainer {
        background-image: url("./pages/images/screen1920.jpg");
        height: 105vh;
    }
    
    .aboutContent {
        padding-top: 28vh;
        padding-right: 41vw;
        padding-left: 42.5vw;
        
        h3 {
            font-size: 60px;
        }

        h4 {
            font-size: 45px;
        }

        p {
            font-size: 35px;
        }
    }

    .skillsContainer {
        h1 {
            padding-top: 0;
            padding-left: 0;
        }
    }

    .icon, .psqlIcon {
        width: 120px;
        height: 120px;
        padding-top: 2vh;
    }
    
    .sequelizeIcon {
        width: 285px;
        height: 120px;
        padding-top: 2vh;
    }
    
    .iconLabel {
        font-size: 45px;
    }
}

@media screen and (max-width: 4096px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        margin-top: 125px;
        height: 195vh;
        
        h1 {
            padding-top: 4vh;
            padding-left: 5vw;
            font-size: 65px;
        }
    }

    .aboutContainer {
        background-image: url("./pages/images/screen1920.jpg");
        height: 140vh;
    }
    
    .aboutContent {
        padding-top: 34vh;
        padding-right: 38vw;
        padding-left: 41vw;
        
        h3 {
            font-size: 60px;
        }

        h4 {
            font-size: 45px;
        }

        p {
            font-size: 35px;
        }
    }

    .skillsContainer {
        h1 {
            padding-top: 0;
            padding-left: 0;
        }
    }

    .icon, .psqlIcon {
        width: 120px;
        height: 120px;
        padding-top: 2vh;
    }
    
    .sequelizeIcon {
        width: 285px;
        height: 120px;
        padding-top: 2vh;
    }
    
    .iconLabel {
        font-size: 45px;
    }
}

@media screen and (max-width: 3840px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        margin-top: 125px;
        height: 195vh;
        
        h1 {
            padding-top: 4vh;
            padding-left: 5vw;
            font-size: 65px;
        }
    }

    .aboutContainer {
        background-image: url("./pages/images/screen1920.jpg");
        height: 140vh;
    }
    
    .aboutContent {
        padding-top: 36vh;
        padding-right: 38vw;
        padding-left: 40vw;
        
        h3 {
            font-size: 60px;
        }

        h4 {
            font-size: 45px;
        }

        p {
            font-size: 35px;
        }
    }

    .skillsContainer {
        h1 {
            padding-top: 0;
            padding-left: 0;
        }
    }

    .icon, .psqlIcon {
        width: 120px;
        height: 120px;
        padding-top: 2vh;
    }
    
    .sequelizeIcon {
        width: 285px;
        height: 120px;
        padding-top: 2vh;
    }
    
    .iconLabel {
        font-size: 45px;
    }
}

@media screen and (max-width: 3000px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        margin-top: 125px;
        height: 155vh;
        
        h1 {
            padding-top: 4vh;
            padding-left: 5vw;
            font-size: 55px;
        }
    }

    .aboutContainer {
        background-image: url("./pages/images/screen1280.jpg");
        height: 105vh;
    }
    
    .aboutContent {
        padding-top: 26vh;
        padding-right: 38vw;
        padding-left: 41vw;
        
        h3 {
            font-size: 42px;
        }

        h4 {
            font-size: 32px;
        }

        p {
            font-size: 25px;
        }
    }

    .skillsContainer {
        h1 {
            padding-top: 0;
            padding-left: 0;
        }
    }

    .icon, .psqlIcon {
        width: 90px;
        height: 90px;
        padding-top: 2vh;
    }
    
    .sequelizeIcon {
        width: 225px;
        height: 90px;
        padding-top: 2vh;
    }
    
    .iconLabel {
        font-size: 35px;
    }
}

@media screen and (max-width: 2880px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 185vh;
    }

    .aboutContainer {
        height: 130vh;
    }
    
    .aboutContent {
        padding-top: 29vh;
        padding-right: 38vw;
        padding-left: 41vw;
    }
}

@media screen and (max-width: 2560px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 200vh;
    }

    .aboutContainer {
        height: 145vh;
    }
    
    .aboutContent {
        padding-top: 35vh;
        padding-right: 36vw;
        padding-left: 40vw;
    }
}

@media screen and (max-width: 1921px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 250vh;
        margin-top: 125px;

        h1 {
            padding-top: 3vh;
            font-size: 40px;
        }
    }

    .aboutContainer {
        height: 185vh;
    }
    
    .aboutContent {
        padding-top: 45vh;
        padding-right: 33vw;
        padding-left: 37.5vw;
        
        h3 {
            font-size: 35px;
        }

        h4 {
            font-size: 27px;
        }

        p {
            font-size: 22px;
        }
    }

    .icon, .psqlIcon {
        width: 70px;
        height: 70px;
    }
    
    .sequelizeIcon {
        width: 160px;
        height: 70px;
    }
    
    .iconLabel {
        font-size: 26px;
    }
}

@media screen and (max-width: 1680px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 250vh;
    }

    .aboutContainer {
        height: 190vh;
    }
    
    .aboutContent {
        padding-top: 46vh;
        padding-right: 29vw;
        padding-left: 34.5vw;
    }
}

@media screen and (max-width: 1600px) {     //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 185vh;
    }
    
    .aboutContainer {
        background-image: url("./pages/images/screen.jpg");
        height: 130vh;    
    }
    
    .aboutContent {
        padding-top: 42vh;
        padding-right: 34vw;
        padding-left: 39vw;

        h3 {
            font-size: 24px;
        }

        h4 {
            font-size: 20px;
        }

        p {
            font-size: 16px;
        }
    }
    
    h4 {
        font-size: 20px;
    }   
    
    .skillsContainer {    
        h1 {
            padding: 0 0 4px;
        }
    }
    
    .icon {
        width: 50px;
        height: 50px;
    }
    
    .psqlIcon {
        width: 40px;
        height: 41.266px;
    }
    
    .sequelizeIcon {
        width: 96px;
        height: 40.078px;
    }
    
    .iconLabel {
        font-size: 20px;
        font-family: Raleway;
        font-weight: bold;
        color: #696055;
    }
    
    ul {
        display: flex;
        margin: 0;
        padding: 0;
    
        li {
        list-style-type: none;
        padding: 0px 10px;
        }
    }
    
    .techIcons {
        margin-top: 35px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 50px;
    }
}

@media screen and (max-width: 1440px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 200vh;
    }

    .aboutContainer {
        height: 140vh;
    }

    .aboutContent {
        padding-top: 46vh;
        padding-right: 33vw;
        padding-left: 37vw;
    }
}

@media screen and (max-width: 1366px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutContent {
        padding-top: 46vh;
        padding-right: 32vw;
        padding-left: 36.5vw;
    }
}

@media screen and (max-width: 1280px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutContainer {
        height: 145vh;
    }
    
    .aboutContent {
        padding-top: 46vh;
        padding-right: 30vw;
        padding-left: 36vw;
    }
}

@media screen and (max-width: 1024px) {         //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 130vh;
    }
    
    .aboutPage h1 {
        margin-bottom: 0;
    }

    .aboutContainer {
        height: 85vh;
    }

    .aboutContent {
        padding-top: 28vh;
        padding-right: 26vw;
        padding-left: 31.5vw;
    }

    .skillsContainer h1 {
        margin-top: 15px;
        margin-left: 30vw;
        margin-right: 30vw;
    }
}

@media screen and (max-width: 768px) {          //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        margin-top: 90px;
        margin-bottom: 50px;
        height: 160vh;
    }

    .aboutPage h1 {
        margin-bottom: 2.5vh;
        font-size: 30px;
    }

    .aboutContainer {
        height: 110vh;
    }

    .aboutContent {
        padding-top: 36vh;
        padding-right: 19vw;
        padding-left: 25.5vw;
    }
}

@media screen and (max-width: 540px) {          //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        margin-top: 70px;
        margin-bottom: 50px;
        height: 145vh;
        width: 90vw;
        margin-left: 5vw;
    }

    .aboutPage h1 {
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 0;
        text-align: center;
        margin: 0;
    }

    .aboutContainer {
        background-image: none;
        background-color: #F2F2F2;
        width: 90vw;
        height: 40vh;
        align-content: flex-start;
    }

    .aboutContent {
        padding: 10px;
    }

    h3 {
        margin-top: 0;
    }

    .skillsContainer h1 {
        margin: 0;
        text-align: center;
        border-bottom: 0px;
    }

    .techIcons {
        margin-top: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-row-gap: 20px;
    }
}

@media screen and (max-width: 414px) {          //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage h1 {
        padding-top: 0;
    }

    .aboutContainer {
        height: 46vh;
    }

    .skillsContainer h1 {
        padding-top: 4vh;
    }
}

@media screen and (max-width: 375px) {          //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 160vh;
    }
    
    .aboutContainer {
        height: 50vh;
    }
}

@media screen and (max-width: 360px) {          //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        height: 170vh;
    }
    
    .aboutContainer {
        height: 60vh;
    }
}

@media screen and (max-width: 320px) {          //++++++++++++++++++++++++++++++++++++++++++
    .aboutPage {
        margin-left: 6vw;
        height: 185vh;
    }

    .aboutContainer {
        height: 67vh;
    }
}

// ================================================ Projects.js ================================================
.projectsPage {
    margin-top: 80px;
    margin-bottom: 100px;
}

.projectLink {
    font-size: 30px;
    color: #696055;
    transition: color 0.3s ease;
    
    &:hover {
        color: white;
        transform: scale(1.5);
        }
}

.dishd {
    width: 55vw;
    height: auto;
}

.project4 {
    background-color: #DDCCBE;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 40px 90px 80px 50px;
    margin: 40px;
}

.p4details {
    background-color: #F3ECE9;
    width: 300px;
    height: 310px;
    margin-top: 6vh;
    padding-left: 15px;
    padding-right: 15px;
}

.brewser {
    width: 60vw;
    height: auto;
}

.project3 {
    background-color: #F2EEEB;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-top: 40px;
    padding-right: 80px;
    padding-bottom: 40px;
}

.p3details {
    background-color: #D9D9D9;
    width: 260px;
    height: 350px;
    margin-top: 15vh;
    padding-left: 15px;
    padding-right: 15px;
}

.whenInRoam {
    width: 50vw;
    height: auto;
    padding-top: 5vh;
}

.project2 {
    background-color: #858585;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 60px 90px 40px 50px;
    width: 80vw;
    margin: 40px;
}

.p2details {
    background-color: #F3ECE9;
    width: 280px;
    height: 285px;
    margin-top: 12vh;
    padding-left: 15px;
    padding-right: 15px;
}

.concentration {
    width: 40vw;
    height: auto;
}

.project1 {
    background-color: #BFA48C;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding: 50px 90px 50px 50px;
}

.p1details {
    background-color: #F2F2F2;
    width: 350px;
    height: 265px;
    margin-top: 10vh;
    padding-left: 15px;
    padding-right: 15px;
}

@media screen and (max-width: 5120px) {         //++++++++++++++++++++++++++++++++++++++++++
    .projectsPage {
        margin-top: 250px;
        margin-bottom: 350px;

        h1 {
            font-size: 70px;
            padding-left: 140px;
        }

        h3 {
            font-size: 60px;
        }

        h5 {
            font-size: 50px;
        }

        p {
            font-size: 48px;
        }
    }
    
    .projectLink {
        font-size: 90px;
        color: #696055;
        transition: color 0.3s ease;
        
        &:hover {
            color: white;
            transform: scale(1.5);
            }
    }
    
    .dishd {
        width: 55vw;
        height: auto;
    }
    
    .project4 {
        background-color: #DDCCBE;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        padding-top: 4vh;
        padding-bottom: 4vh;
        padding-left: 4vw;
        margin: 120px;
    }
    
    .p4details {
        background-color: #F3ECE9;
        width: 23vw;
        height: 23vh;
        margin-top: 6vh;
        padding-left: 60px;
        padding-right: 60px;
    }
    
    .brewser {
        width: 60vw;
        height: auto;
    }
    
    .project3 {
        background-color: #F2EEEB;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        padding-top: 6vh;
        padding-right: 5vw;
        padding-bottom: 3vh;
    }
    
    .p3details {
        background-color: #D9D9D9;
        width: 20vw;
        height: 27vh;
        margin-top: 15vh;
        padding-left: 60px;
        padding-right: 60px;
    }
    
    .whenInRoam {
        width: 50vw;
        height: auto;
        padding-top: 5vh;
    }
    
    .project2 {
        background-color: #858585;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 80vw;
        margin: 120px;
        padding-top: 5vh;
        padding-bottom: 8vh;
        padding-left: 3vw;
    }
    
    .p2details {
        background-color: #F3ECE9;
        width: 15vw;
        height: 26vh;
        margin-top: 1vh;
        padding-left: 60px;
        padding-right: 60px;
    }
    
    .concentration {
        width: 40vw;
        height: auto;
    }
    
    .project1 {
        background-color: #BFA48C;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        padding-top: 4vh;
        padding-right: 8vw;
        padding-bottom: 4vh;
    }
    
    .p1details {
        background-color: #F2F2F2;
        width: 25vw;
        height: 24vh;
        margin-top: 20vh;
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media screen and (max-width: 4096px) {         //++++++++++++++++++++++++++++++++++++++++++
    .p4details {
        height: 34vh;
    }
    
    .p3details {
        height: 36vh;
    }
    
    .p2details {
        height: 38vh;
    }
    
    .p1details {
        height: 29vh;
    }
}

@media screen and (max-width: 3840px) {         //++++++++++++++++++++++++++++++++++++++++++
    .p4details {
        height: 35vh;
    }
    
    .p3details {
        height: 44vh;
    }
    
    .p2details {
        height: 40vh;
    }
    
    .p1details {
        height: 33vh;
    }
}

@media screen and (max-width: 3000px) {         //++++++++++++++++++++++++++++++++++++++++++
    .projectsPage {
        margin-top: 170px;
        margin-bottom: 170px;

        h1 {
            font-size: 50px;
        }

        h3 {
            font-size: 40px;
        }

        h5 {
            font-size: 30px;
        }

        p {
            font-size: 28px;
        }
    }
    
    .projectLink {
        font-size: 60px;
    }
    
    .project4 {
        padding: 40px 90px 80px 50px;
        margin: 40px;
    }
    
    .p4details {
        height: 23vh;
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .project3 {
        padding-top: 40px;
        padding-right: 80px;
        padding-bottom: 40px;
    }
    
    .p3details {
        height: 27vh;
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .project2 {
        padding: 60px 90px 40px 50px;
        margin: 40px;
    }
    
    .p2details {
        height: 26vh;
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .project1 {
        padding: 50px 90px 50px 50px;
    }
    
    .p1details {
        height: 24vh;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 2560px) {         //++++++++++++++++++++++++++++++++++++++++++
    .p4details {
        height: 34vh;
    }
    
    .p3details {
        height: 35vh;
    }
    
    .p2details {
        height: 35vh;
    }
    
    .p1details {
        height: 29vh;
    }
}

@media screen and (max-width: 1921px) {         //++++++++++++++++++++++++++++++++++++++++++
    .projectsPage {
        margin-top: 120px;
        margin-bottom: 120px;

        h1 {
            font-size: 36px;
            padding-left: 90px;
        }

        h3 {
            font-size: 24px;
        }

        h5 {
            font-size: 18px;
        }

        p {
            font-size: 18px;
        }
    }
    
    .projectLink {
        font-size: 30px;
    }
    
    .p4details {
        width: 300px;
        height: 310px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .p3details {
        width: 260px;
        height: 350px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .p2details {
        width: 280px;
        height: 285px;
        margin-top: -2vh;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .p1details {
        width: 350px;
        height: 265px;
        margin-top: 10vh;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 1440px) {         //++++++++++++++++++++++++++++++++++++++++++
    .projectsPage {
        margin-top: 80px;
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 1024px) {         //++++++++++++++++++++++++++++++++++++++++++
    .projectsPage {
        margin-top: 90px;
    }

    .project4, .project1 {
        margin: 20px 0 20px 0;
        padding: 0;
    }

    .project3, .project2 {
        margin: 20px;
        padding: 0;
    }

    .dishd {
        width: 60vw;
        margin-left: 5vw;
        margin-top: 3vh;
    }

    .p4details {
        width: 36vw;
        height: 19vh;
        margin-top: 0;
        margin-left: 48vw;
        margin-bottom: 3vh;
        padding-left: 15px;
        padding-right: 15px;
    }

    .p3details {
        width: 325px;
        height: 305px;
        margin-top: 3vh;
        margin-right: 5vw;
        padding-left: 15px;
        padding-right: 15px;
    }

    .brewser {
        width: 65vw;
        margin-right: 20vw;
        margin-bottom: 3vh;
    }

    .project2 {
        width: 94.5vw;
    }

    .whenInRoam {
        width: 70vw;
        padding-top: 3vh;
        margin-left: 4vw;
    }

    .p2details {
        width: 280px;
        height: 285px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0;
        margin-bottom: 3vh;
        margin-left: 56vw;
    }

    .p1details {
        width: 35vw;
        height: 265px;
        margin-top: 9vh;
        padding-left: 15px;
        padding-right: 15px;
    }

    .concentration {
        width: 40vw;
        margin: 3vh 5vw 3vh 0;
    }
}

@media screen and (max-width: 768px) {          //++++++++++++++++++++++++++++++++++++++++++
    .p4details {
        height: 30vh;
    }

    .p3details {
        width: 40vw;
        height: 30vh;
    }

    .p2details {
        width: 35vw;
        height: 28vh;
        margin-left: 45vw;
    }

    .p1details {
        height: 28vh;
    }
}

@media screen and (max-width: 540px) {          //++++++++++++++++++++++++++++++++++++++++++
    .projectsPage {
        width: 100%;
        margin-bottom: 80px

        h1 {
            padding: 0;
            text-align: center;
        }

        h3 {
            padding-top: 5px;
            font-size: 18px;
        }

        h5 {
            font-size: 14px;
        }

        p {
            font-size: 14px;
        }
    }

    .project4, .project3, .project1 {
        margin: 15px;
        padding: 10px;
    }
    
    .project2 {
        margin: 15px 18px 15px 18px;
        padding: 10px;
        width: 90vw;
    }
    
    .dishd {
        width: 90vw;
        height: 50vh;
        margin: 0;
    }

    .brewser {
        width: 90vw;
        height: 55vh;
        margin: 0;
    }
    
    .whenInRoam {
        width: 90vw;
        height: 35vh;
        padding: 0;
        margin: 0;
    }

    .concentration {
        width: 90vw;
        height: 100vh;
        margin: 0;
    }

    .p4details {
        width: 84.5vw;
        height: 27vh;
        margin: 0;
    }
    
    .p3details {
        width: 84.5vw;
        height: 27vh;
        margin: 0;
    }

    .p2details {
        width: 90vw;
        height: 25vh;
        margin: 0;
    }

    .p1details {
        width: 84.5vw;
        height: 27vh;
        margin: 0;
    }
}

@media screen and (max-width: 414px) {          //++++++++++++++++++++++++++++++++++++++++++
    .projectsPage {
        margin-top: 70px;
    }

    .project2 {
        margin: 15px 30px 15px 30px;
        width: 80vw;
    }
    
    .dishd {
        width: 88vw;
        height: 38vh;
    }
    
    .brewser {
        width: 88vw;
        height: 45vh;
    }

    .whenInRoam {
        width: 80vw;
        height: 30vh;
    }

    .concentration {
        width: 88vw;
        height: 85vh;
    }

    .p4details {
        width: 81vw;
        height: 30vh;
    }
    
    .p3details {
        width: 81vw;
        height: 31vh;
    }

    .p2details {
        width: 81vw;
        height: 30vh;
    }

    .p1details {
        height: 27vh;
    }
}

@media screen and (max-width: 375px) {          //++++++++++++++++++++++++++++++++++++++++++
    .project2 {
        margin: 15px 26.5px 15px 26.5px;
    }
    
    .dishd, .brewser {
        width: 86vw;
        height: 31vh;
    }
    
    .whenInRoam {
        height: 20vh;
    }

    .concentration {
        width: 86.75vw;
        height: 60vh;
    }
    
    .p4details {
        height: 35vh;
    }

    .p3details {
        width: 78vw;
        height: 35vh;
    }

    .p2details, .p1details {
        height: 33vh;
    }
}

@media screen and (max-width: 360px) {          //++++++++++++++++++++++++++++++++++++++++++
    .dishd {
        width: 86vw;
        height: 35vh;
    }
    
    .brewser {
        width: 86vw;
        height: 38vh;
    }

    .whenInRoam {
        height: 25vh;
    }

    .concentration {
        width: 86vw;
        height: 75vh;
    }

    .p4details {
        height: 35vh;
    }
    
    .p3details {
        width: 80vw;
        height: 38vh;
    }

    .p2details, .p1details {
        height: 33vh;
    }
}

@media screen and (max-width: 320px) {          //++++++++++++++++++++++++++++++++++++++++++
    .project2 {
        width: 82vw;
        margin: 15px 21px 15px 21px;
    }
    
    .dishd {
        width: 84vw;
    }

    .brewser {
        width: 84.5vw;
    }
    
    .whenInRoam {
        width: 82vw;
        height: 28vh;
    }

    .concentration {
        width: 84.5vw;
    }
    
    .p4details {
        height: 42vh;
    }
    
    .p3details {
        height: 46vh;
    }

    .p2details, .p1details {
        height: 40vh;
    }
}

@media screen and (max-width: 280px) {          //++++++++++++++++++++++++++++++++++++++++++
    .project4, .project3, .project2, .project1 {
        margin: 15px;
        padding: 10px;
    }

    .dishd {
        width: 82vw;
        height: 30vh;
    }
    
    .brewser {
        width: 82vw;
        height: 33vh;
    }

    .whenInRoam {
        height: 23vh;
    }

    .concentration {
        width: 82vw;
        height: 65vh;
    }
}