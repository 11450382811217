footer {
    background-color: #D5D5D5;
    padding: 1vh 0 1vh 0;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 4.5vh;
    bottom: 0px;
    left: 0px;
    z-index: 5;

    a {
        color: white;
        transition: color 0.3s ease;
        font-size: 3vw;

        &:hover {
        color: darken(#fff, 70%);
        transform: scale(1.5);
        }
    }

    ul {
        display: flex;

        li {
        list-style-type: none;
        padding: 0px 15px;
        }
    }
}

@media screen and (max-width: 5120px) {
    footer {
        padding: 2vh 0 4vh 0;
        height: 4vh;
    }
}

@media screen and (max-width: 4096px) {
    footer {
        height: 3vh;
    }
}

@media screen and (max-width: 2560px) {
    footer {
        padding: 2vh 0 3vh 0;
    }
}

@media screen and (max-width: 1921px) {
    footer {
        padding: 2vh 0 3vh 0;
        height: 5vh;
    }
}

@media screen and (max-width: 540px) {
    footer a {
        font-size: 5vw;
    }
}

@media screen and (max-width: 414px) {
    footer a {
        font-size: 6vw;
    }
}

@media screen and (max-width: 320px) {
    footer a {
        font-size: 7vw;
    }
}
